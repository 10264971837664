import React from 'react';
import Mire from './Mire';
import { Base } from '../../widgets/Base/Base';
import { compose, withStateHandlers } from 'recompose';
import { getLoginInformation } from '../../selectors/authentication';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import Background1 from '../../utils/styles/images/showroom-background-2.jpg';
import Background2 from '../../utils/styles/images/showroom-background-3.jpg';

const Component = ({ onClose, isOpen, loginInformation }) => {

    const subTitle1 = "vous invite à\nvous identifier";
    const subTitle2 = "vous invite à générer\nvotre code d'accès";

    return (
        <Base title="Votre esprit d'analyse" subTitle={!loginInformation ? subTitle1 : subTitle2} backgroundImage={!loginInformation ? Background1 : Background2}>
            <Mire open={isOpen} onClose={onClose}  />
        </Base>
    )
}

const mapStateToProps = createStructuredSelector({
    loginInformation: getLoginInformation,
});

const withDialog = withStateHandlers(() => ({ isOpen: true }), {
    onClose: () => value => ({ isOpen: !value }),
})

export const enhance = compose(
    connect(mapStateToProps),
    withDialog
);

export default enhance(Component);