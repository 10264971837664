import React from 'react';
import Layout from './Layout';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import Connection from '../Connection';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid'
import Admin from '../Admin';
import Footer from './Layout/Footer';
import HealthCheck from '../../pages/HealthCheck'
import { getLoggedInStatus } from '../../selectors/authentication';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ShowroomBanner } from '../../widgets/ShowroomBanner/ShowroomBanner';
import Header from '../../widgets/Header/Header';

const App = ({ isLoggedIn }) => {
    // if( sessionStorage.getItem("loggedIn") && (sessionStorage.getItem("loggedIn") === "true")){
    //     window.location.href = '/admin';
    // }
    return (
        <Grid container>
            <Header />
            <ShowroomBanner />
            {( ( sessionStorage.getItem("loggedIn") && ( sessionStorage.getItem("loggedIn") === "true" ) ) || isLoggedIn ) ? 
                <Redirect to="/admin" />
                : 
                <Redirect to="/" />
            }
            <Layout isLoggedIn={isLoggedIn} />
            <Grid className="materialBaseGrid" item sm={12} style={{ minHeight: 1, maxWidth: '100%', width: '100%' }}>
                <Route exact path='/' component={Connection} />
                <Route exact path='/connect' component={Connection} />
                {
                    ( ( sessionStorage.getItem("loggedIn") && ( sessionStorage.getItem("loggedIn") === "true" ) ) || isLoggedIn ) && <Route exact path='/admin' component={Admin} />
                }
                <Route exact path='/info' component={HealthCheck} />
            </Grid>
        </Grid>

    );
}
const mapStateToProps = createStructuredSelector({
    isLoggedIn: getLoggedInStatus
})
export const enhance = compose(
    connect(mapStateToProps, null)
)
export default enhance(App);
