export const saveAuthenticationState = (isLoggedIn) => {
    const serializedState = JSON.stringify(isLoggedIn);
    sessionStorage.setItem('isLoggedIn', serializedState);
};
export const loadAuthenticationState = () => {
    const serializedState = sessionStorage.getItem('isLoggedIn');
    return JSON.parse(serializedState);
};

export const saveToken = (token) => {
    const serializedState = JSON.stringify(token);
    sessionStorage.setItem('token', serializedState);
};
export const loadToken = () => {
    const serializedState = sessionStorage.getItem('token');
    if (serializedState && serializedState !== 'undefined' && serializedState !== undefined) {
        return JSON.parse(serializedState);
    }
    else {
        return JSON.parse('{}')
    }
};