import React from 'react';

import { Base } from '../../widgets/Base/Base';
import { Accordion } from '../../widgets/Accordion/Accordion';

import BackgroundImage from '../../utils/styles/images/showroom-background-1.jpg';

export const Component = () => {

    const content = [
        {
            title: 'DSP2',
            listElements:
                <ul>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32015L2366'>
                            Directive (UE) 2015/2366 du Parlement européen et du Conseil 
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://www.banque-france.fr/sites/default/files/medias/documents/2019-09-11_osmp_-_plan_de_migration_dsp2.pdf'>
                        Banque-de-France : 2019-09-11 Plan_de_migration_DSP2   
                        </a>                             
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://www.banque-france.fr/sites/default/files/medias/documents/819172_osmp2018_web_3.pdf'>
                        Banque de France : Rapport 2018 - de l’Observatoire de la sécurité des moyens 
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='http://www.fbf.fr/fr/files/AU8CQN/MEMO_08_DSP2.pdf'>
                        Fédération Bancaire Française : La DSP2 et les enjeux de sécurité
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" href='https://www.tlmr-avocats.com/fiche-pratique-quest-ce-que-la-dsp2' target='_blank'>
                            Qu’est-ce que la DSP2 ? Comment s’y conformer ?
                        </a>
                    </li>
                </ul>,
        },
        {
            title: 'CyberSecurity Act (EU) & Certification',
            listElements:
                <ul>
                    <li>
                        <a rel="noopener noreferrer"  href='https://ec.europa.eu/digital-single-market/en/eu-cybersecurity-act' target='_blank'>
                        The EU Cybersecurity Act
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://ec.europa.eu/digital-single-market/en/eu-cybersecurity-certification' target='_blank'>
                        The EU cybersecurity certification framework
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.ssi.gouv.fr/entreprise/reglementation/cybersecurity-act-2/le-cadre-de-certification-europeen/'>
                        Le cadre de certification européen
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.ssi.gouv.fr/entreprise/produits-certifies/produits-certifies-cspn/les-procedures-formulaires-et-methodologies/' target='_blank'>
                        Les procédures, formulaires et méthodologies
                        </a>
                    </li>
                </ul>
        },
        {
            title: 'Authentification',
            listElements:
                <ul>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://ieeexplore.ieee.org/abstract/document/8256716'>
                            Survey on usable and secure two-factor authentication
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://arxiv.org/ftp/arxiv/papers/1208/1208.1918.pdf'>
                            A survey on authentication and key agreement protocols in Heterogeneous network
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://pages.nist.gov/800-63-3/sp800-63b.html'>
                            NIST Special Publication 800-63B : Digital Identity Guidelines - Authentication and Lifecycle Management
                        </a>
                    </li>
                </ul>
        },
        {
            title: 'Divers',
            listElements:
                <ul>
                    <li>
                        <a rel="noopener noreferrer"  href='http://www3.weforum.org/docs/WEF_A_Blueprint_for_Digital_Identity.pdf' target='_blank'>
                            A Blue print for Digital Identity
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.laposte.fr/entreprise/content/download/7289/197721/version/anc+du+courrier+cliquable+en+telechargement.pdf' target='_blank'>
                            Comment rendre votre courrier interactif en y intégrant un tag 2D ?
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.434.8252&amp;rep=rep1&amp;type=pdf'>
                            A Survey on QR Codes: in context of Research and Application
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.banque-france.fr/intervention/le-secteur-bancaire-face-aux-enjeux-davenir-le-digital-et-la-finance-verte' target='_blank'>
                            Le secteur bancaire face aux enjeux d’avenir : le digital et la finance verte
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.cnil.fr/fr/securite-authentifier-les-utilisateurs' target='_blank'>
                            Sécurité : Authentifier les utilisateurs
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer"  href='https://www.usine-digitale.fr/article/le-human-centric-design-une-ethique-industrielle.N194510' target='_blank'>
                            Le "Human-Centric Design", une éthique industrielle 
                        </a>
                    </li>
                </ul>
        },
        {
            title: 'Human Inside',
            listElements:
                <ul>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://dl.acm.org/citation.cfm?id=3004121'>
                            Experimental studies of a graphical authentication system based on semantic categorisation
                        </a>
                    </li>
                    <li>
                        <a  rel="noopener noreferrer"  target='_blank' href='https://ieeexplore.ieee.org/abstract/document/7336336'>
                            Virtual Keyboard Logging Counter-Measures Using Human Vision Properties
                        </a>
                    </li>
                </ul>
        }
    ];

    return (
        <Base title="Bienvenue" subTitle="dans votre espace" backgroundImage={BackgroundImage} >
            <Accordion content={content} />
        </Base>
    );
}

// class Component extends React.Component {
//     constructor(props) {
//       super(props)
//       this.state = {
//       }
//       this.toggleClass=this.toggleClass.bind(this);
//     }
//      toggleClass(e){
//         const elem = e.target;
//         const content = document.getElementById(elem.getAttribute("data-num"));
//         if(elem.getAttribute("data-clicked") == 'false'){
//             content.classList.add("show");
//             elem.classList.remove('show');
//             elem.classList.add("hide");
//             elem.setAttribute("data-clicked", 'true');
//         }else{
//             content.classList.remove("show");
//             elem.classList.remove('hide');
//             elem.classList.add("show");
//             elem.setAttribute("data-clicked", 'false');
//         }
//      }
//     render() {
//         var divStyle = {
//             textAlign: 'center',
//             color: 'white',
//             position: 'relative',
//             marginBottom: '50px',
//             marginTop:'70px',
//         };
//         return (        
//             <div style={{ zIndex:2, position: "relative"}}>           
//                 <div className='hia_logo_Top_Left'><img style={{position :"absolute", right:30,top:30,height:70}} src="logo-HIA-secure-sans-secure-white.png" alt="logo HIAsecure"/></div>
//                 <div style={divStyle}>
//                     <div className='hia_logo_bienvenu'><img className='hia_img_welcome' src="showroom.png" alt="logo Showroom HIAsecure"/></div>
//                     <h2 style={{marginTop: 0}}>Welcome to our library</h2>
//                     <p className='hia_desc'>A library of reports, white papers and regulatory texts selected by our cybersecurity experts.</p>
    
//                 </div>
//                 <div className='hia_posts'>
//                     <div className='hia_post_block' id="one">
//                         <div className='hia_post_title'><h3>DSP2</h3></div>
//                         <div className='hia_post_content'>
//                             <ul>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32015L2366'>
//                                         Directive (UE) 2015/2366 du Parlement européen et du Conseil 
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://www.banque-france.fr/sites/default/files/medias/documents/2019-09-11_osmp_-_plan_de_migration_dsp2.pdf'>
//                                     Banque-de-France : 2019-09-11 Plan_de_migration_DSP2   
//                                     </a>                             
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://www.banque-france.fr/sites/default/files/medias/documents/819172_osmp2018_web_3.pdf'>
//                                     Banque de France : Rapport 2018 - de l’Observatoire de la sécurité des moyens 
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='http://www.fbf.fr/fr/files/AU8CQN/MEMO_08_DSP2.pdf'>
//                                     Fédération Bancaire Française : La DSP2 et les enjeux de sécurité
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer" href='https://www.tlmr-avocats.com/fiche-pratique-quest-ce-que-la-dsp2' target='_blank'>
//                                         Qu’est-ce que la DSP2 ? Comment s’y conformer ?
//                                     </a>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div className="hia_read_more show" data-num="one" data-clicked='false' onClick={(e) => this.toggleClass(e)}></div>
//                     </div>
//                     <div className='hia_post_block' id="two">
//                         <div className='hia_post_title'><h3>CyberSecurity Act (EU) & Certification</h3></div>
//                         <div className='hia_post_content'>
//                             <ul>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://ec.europa.eu/digital-single-market/en/eu-cybersecurity-act' target='_blank'>
//                                     The EU Cybersecurity Act
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://ec.europa.eu/digital-single-market/en/eu-cybersecurity-certification' target='_blank'>
//                                     The EU cybersecurity certification framework
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.ssi.gouv.fr/entreprise/reglementation/cybersecurity-act-2/le-cadre-de-certification-europeen/'>
//                                     Le cadre de certification européen
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.ssi.gouv.fr/entreprise/produits-certifies/produits-certifies-cspn/les-procedures-formulaires-et-methodologies/' target='_blank'>
//                                     Les procédures, formulaires et méthodologies
//                                     </a>
//                                 </li>
//                             </ul>
//                         </div>                        
//                         <div className="hia_read_more show"  data-num="two" data-clicked='false' onClick={(e) => this.toggleClass(e)}></div>
//                     </div>
//                     <div className='hia_post_block' id="tree">
//                         <div className='hia_post_title'><h3>Authentification</h3></div>
//                         <div className='hia_post_content'>
//                             <ul>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://ieeexplore.ieee.org/abstract/document/8256716'>
//                                         Survey on usable and secure two-factor authentication
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://arxiv.org/ftp/arxiv/papers/1208/1208.1918.pdf'>
//                                         A survey on authentication and key agreement protocols in Heterogeneous network
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://pages.nist.gov/800-63-3/sp800-63b.html'>
//                                         NIST Special Publication 800-63B : Digital Identity Guidelines - Authentication and Lifecycle Management
//                                     </a>
//                                 </li>
//                             </ul>
    
//                         </div>
//                         <div className="hia_read_more show"  data-num="tree" data-clicked='false' onClick={(e) => this.toggleClass(e)}></div>
//                     </div>
//                     <div className='hia_post_block' id="four">
//                         <div className='hia_post_title'><h3>Divers</h3></div>
//                         <div className='hia_post_content'>
//                             <ul>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='http://www3.weforum.org/docs/WEF_A_Blueprint_for_Digital_Identity.pdf' target='_blank'>
//                                         A Blue print for Digital Identity
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.laposte.fr/entreprise/content/download/7289/197721/version/anc+du+courrier+cliquable+en+telechargement.pdf' target='_blank'>
//                                         Comment rendre votre courrier interactif en y intégrant un tag 2D ?
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.434.8252&amp;rep=rep1&amp;type=pdf'>
//                                         A Survey on QR Codes: in context of Research and Application
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.banque-france.fr/intervention/le-secteur-bancaire-face-aux-enjeux-davenir-le-digital-et-la-finance-verte' target='_blank'>
//                                         Le secteur bancaire face aux enjeux d’avenir : le digital et la finance verte
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.cnil.fr/fr/securite-authentifier-les-utilisateurs' target='_blank'>
//                                         Sécurité : Authentifier les utilisateurs
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a rel="noopener noreferrer"  href='https://www.usine-digitale.fr/article/le-human-centric-design-une-ethique-industrielle.N194510' target='_blank'>
//                                         Le "Human-Centric Design", une éthique industrielle 
//                                     </a>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div className="hia_read_more show"  data-num="four" data-clicked='false' onClick={(e) => this.toggleClass(e)}></div>
//                     </div>
//                     <div className='hia_post_block'>
//                         <div className='hia_post_title'><h3>Human Inside</h3></div>
//                         <div className='hia_post_content'>
//                             <ul>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://dl.acm.org/citation.cfm?id=3004121'>
//                                         Experimental studies of a graphical authentication system based on semantic categorisation
//                                     </a>
//                                 </li>
//                                 <li>
//                                     <a  rel="noopener noreferrer"  target='_blank' href='https://ieeexplore.ieee.org/abstract/document/7336336'>
//                                         Virtual Keyboard Logging Counter-Measures Using Human Vision Properties
//                                     </a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
//   }

export default Component;