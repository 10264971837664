import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    width: 300,
  },
  title: {
    margin: '0 auto',
  },
});
const TextFieldForm = ({
  input: { value, onChange, name, ...restInput },
  meta: { error, touched },
  classes,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={restInput}
      error={error && touched}
      helperText={touched ? error : undefined}
      margin="dense"
      fullWidth
    />
  )
};

TextFieldForm.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object,
};
export default withStyles(styles)(TextFieldForm);