import React from 'react';
import { compose } from 'recompose';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import HiaFooter from '../HiaFooter';

const Component = ({ title, action, open, children ,actions}) => {
    return (
        <Dialog
            onClose={() => {
                action(open); actions.setLogin();
                actions.setValidateLoginError();
            }} open={open}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="simple-dialog-title">
                {title}
            </DialogTitle>
            {children}
            <HiaFooter />
        </Dialog>
    )
}

export const enhance = compose();
export default enhance(Component);