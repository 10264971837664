import axios from 'axios';

export const getToken = async () => {
    try {
        const response = await axios.post('/auth/realms/hia-realm/protocol/openid-connect/token');
        const token = response.data.access_token;
        return token;
    } catch (error) {
        console.error('Error fetching the token', error);
        throw new Error('Error fetching the token');
    }
}
