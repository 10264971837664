import React from 'react';
import { compose } from 'recompose';
import '../../../../utils/styles/Footer.css'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    footer: {
        position: "fixed",
        bottom: 0
    }
})
const Component = ({ classes }) => {
    return (
        <div className={classes.footer} id="footer">
            &nbsp;
        </div>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);