import React from 'react';

import BannerBackground from '../../utils/styles/images/banner.jpg';

import './ShowroomBanner.css';

export const ShowroomBanner = () => {
    return (
        <div id="showroomBanner" style={{backgroundImage: `url("${BannerBackground}")`}}>
            <p>Showroom</p>
            <div className="showroomBannerGradientBorder"></div>
        </div>
    );
}