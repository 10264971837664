import React from 'react';

export const renderer = ({ minutes, seconds, completed }) => {

    let minute = minutes
    let second = seconds
    
    if (!completed) {


        if (minute < 10) {
            minute = `0${minute}`
        }
        if (second < 10) {
            second = `0${second}`
        }
        if (seconds <= 5) {
            return (<span style={{ color: "black" }}>aloo
            </span>);
        }

        return (<span style={{ color: "black" }}>{minute}:{second}</span>);
    }

    return null;
};

export const displayChallengeIntegrated = (loginInformation) => {
    return 'APPS' !== loginInformation?.challengeReceptionMode
}

export const emailContact = () => {
    return `mailto:${process.env.REACT_APP_CONTACT}?subject=Convention suspended`;
}

export const NumberOfTry = (number) => {
    let msgId = undefined
    if (number > 1) {
        msgId = "challenge.attempts"
    }
    if (number === 1) {
        msgId = "challenge.attempts.one"
    }
    if (number === undefined) {
        msgId = "hia.convention.blocked"
    }
    return msgId
}
