import React from 'react';

import './Base.css';

export const Base = (props) => {

    const { title, subTitle, children, backgroundImage, className } = props;

    return (
        <div id="base" className={className} style={{backgroundImage: `url("${backgroundImage}")`}}>
            <div className="leftBox">
                <p className="baseTitle"><span>*</span>{title}</p>
                <p className="baseSubTitle">{subTitle}</p>
            </div>

            <div className="centerBox">
                { children }
            </div>

            <div className="rightBox">
            </div>
        </div>
    );
}