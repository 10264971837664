import React from 'react';

const Page =()=>{

    return (
        <div>Ok</div>
    )
}


export default Page;