import React from 'react';
import { compose } from 'recompose';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { emailContact } from '../utils/index'

const Component = () => {
    return (
        <DialogContent>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <a href={emailContact()} target="_blank" rel="noopener noreferrer">
                            < Button color="primary" >
                                CONTACT ADMIN
                            </Button>
                        </a>
                        &nbsp;
                        </Grid>
                </Grid>
            </Grid>
        </DialogContent>

    )
}

export const enhance = compose();
export default enhance(Component);