import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';


const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const Component = ({ justify = "center", backgroundColor,children }) => {
    const style = ({
        backgroundColor: backgroundColor,
        justifyContent: justify,
    });
    const props = { style }
    return (
        <DialogActions {...props}>
           {children}
        </DialogActions>
    )
}

export const enhance = compose();
export default enhance(Component);