import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import DialogActions from './Dialog/DialogFooter';
import { withStyles } from '@material-ui/core';

const styles = theme => ({

});
const Component = ({ classes }) => {
    return (
        <DialogActions style={{border:"none"}}>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container justify="flex-end" >
                        <Typography style={{color:"#000",fontStyle:'italic',fontSize:"14px",fontWeight:600}} >
                            Protected by <img src="logo-HIA-secure_footer.png" alt="Logo HIASecure white" style={{verticalAlign: 'text-bottom'}}/>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);