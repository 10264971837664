import React from 'react';
import { compose } from 'recompose';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

const Component = ({ onClose }) => {
    return (
        <DialogContent >
            <Grid container>

                <Grid item xs={12}>
                    <Grid container justify="center">
                        < Button color="primary" onClick={() => onClose(true)}>
                            Close
                            </Button>
                        &nbsp;
                        </Grid>
                </Grid>
            </Grid>
        </DialogContent>

    )
}

export const enhance = compose();
export default enhance(Component);