import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import '../../../utils/styles/Mire.css';
import '../../../utils/styles/header.css';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../../actions/authentication';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLoginErrors, getLoginInformation, getChallengeInformation, getResponseErrors, getLoggedInStatus, getValidLoginError, getLogin } from '../../../selectors/authentication';
import BeforeLogin from './BeforeLogin';
import AfterLogin from './AfterLogin';
import ConventionBlocked from './ConventionBlocked';
import Dialog from '../../../widgets/Dialog/index.js';
import HiaFooter from '../../../widgets/HiaFooter';

import './index.css';

const styles = theme => ({
    actions: {
        justifyContent: 'center'
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
});


const Component = ({ onClose, open, actions, error, loginInformation, challengeInformation, responseError, isLoggedIn, validLoginError, login }) => {
    return (
        <div id="mire">
            <div className="mireContainer">
                <p className="mireTitle">Sign in</p>

                {!loginInformation ?
                <>
                <BeforeLogin onClose={onClose} error={error} actions={actions}  isLoggedIn={isLoggedIn} validLoginError={validLoginError} login={login} />
                </>
                    
                    :

                    responseError !== "KO_BLOCKED" ?
                        <AfterLogin actions={actions}
                            loginInformation={loginInformation}
                            challengeInformation={challengeInformation}
                            responseError={responseError}
                            isLoggedIn={isLoggedIn}
                            onClose={onClose}
                            validLoginError={validLoginError}
                        /> :
                        <ConventionBlocked responseError={responseError} actions={actions} loginInformation={loginInformation} validLoginError={validLoginError} />
                }
                <HiaFooter />
            </div>
        </div>
    );

}
const actions = {
    validateLogin: authenticationActions.validateLogin,
    getChallenge: authenticationActions.getChallenge,
    answerChallenge: authenticationActions.answerChallenge,
    checkChallenge: authenticationActions.checkChallenge,
    createNewConvention: authenticationActions.createNewConvention,
    setValidateLoginError: authenticationActions.setValidateLoginError,
    setLogin: authenticationActions.setLogin
};


const mapStateToProps = createStructuredSelector({
    error: getLoginErrors,
    loginInformation: getLoginInformation,
    challengeInformation: getChallengeInformation,
    responseError: getResponseErrors,
    isLoggedIn: getLoggedInStatus,
    validLoginError: getValidLoginError,
    login: getLogin
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const loadComponents = lifecycle({
    componentWillUnmount() {
        const { actions } = this.props
        //actions.setClientErrors();
        //actions.setLogin();
        //actions.setValidateLoginError();
    },
},
);
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    loadComponents);
export default enhance(Component)
