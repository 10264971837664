import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Grid,Typography } from '@material-ui/core';

const Component = ({ toggleDialog, actions, validLoginError, login }) => {

    return (
        <DialogContent>
            <Grid container>

                <Grid item xs={12}>
                    <Grid container justify="center">
                        <a href="https://hiasecure.com/contact/" target="_blank" rel="noopener noreferrer"> 
                            < Button color="primary" >
                                ASK NEW CONVENTION
                            </Button>
                        </a>
                        &nbsp;
                        </Grid>
                </Grid>
            </Grid>
        </DialogContent>

    )
}
const withDialog = withStateHandlers(() => ({ isDialogOpen: false }), {
    toggleDialog: () => value => ({ isDialogOpen: value }),
});

export const enhance = compose(withDialog);
export default enhance(Component);